<template>
  <div class="page-box">
    <div class="nav-fixed-box">
      <Nav class="nav-fixed" :navFixed="navFixed"  @click.native="scrollView($event)"></Nav>
    </div>
    <el-scrollbar ref="view-box" style="position: relative;flex: 1;height:100%;box-sizing: border-box;">
      <div class="view-box">
        <p style="font-size:26pt; line-height:175%; margin:0pt 0pt 10pt; text-align:center"><a
            name="_055-1599121612466"></a><span
            style="color:#333333;  font-size:26pt; font-weight:bold">应用兼容性标准</span></p>
        <p style="font-size:11pt; line-height:175%; margin:10pt 0pt 10pt"><a name="_240-1599121613409"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:175%; margin:10pt 0pt 10pt"><a name="_063-1599121613414"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:175%; margin:10pt 0pt 10pt"><a name="_497-1599121658980"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="margin-top: 20pt;margin-bottom:10pt" class="nav-title" id="1"><a name="_053-1599121659560"></a><span
            style="color:#409eff;  font-size:17pt; font-weight:bold">一. 背景</span></p>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt"><a name="_795-1599121613442"></a><span
            style="color:#595959;  font-size:12pt">应用兼容性标准目的是提升安卓应用体验，营造良好的安卓应用使用环境，推动安卓应用软件运行在手机上的健康发展，建立安全可靠、值得信赖、健康的应用生态环境体系。</span>
        </p>
        <p style="font-size:11pt; line-height:175%; margin:10pt 0pt 10pt"><a name="_842-1599121853769"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="margin-top: 20pt;margin-bottom:10pt" class="nav-title" id="2"><a name="_011-1599121613448"></a><span
            style="color:#409eff;  font-size:17pt; font-weight:bold" >二. 适用范围</span></p>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt"><a name="_336-1599121613450"></a><span
            style="color:#595959;  font-size:12pt">本标准适用于Android平台各类应用的兼容性衡量与判断。应用类型涉及：视频音乐、通讯社交、摄影美图、新闻阅读、购物优惠、生活服务、实用工具、教育学习、系统工具、金融理财、旅游出行、娱乐消遣、育儿母婴、健康美容、效率办公等。</span>
        </p>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt"><a name="_679-1599121613455"></a><span
            style="color:#595959;  font-size:12pt">安卓应用兼容性有两大维度：一是已有应用在安卓新版本上的兼容性表现；二是新应用在不同安卓设备上的兼容性表现。本文档主要聚焦已有应用在安卓新版本上的兼容性。</span>
        </p>
        <p style="font-size:11pt; line-height:175%; margin:10pt 0pt 10pt"><a name="_620-1599121613460"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt 21pt"><a name="_447-1599445199134"></a><span
            style="color:#595959;  font-size:12pt">兼容性问题的表象，大致可以归类到以下五大类：</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt 21pt; text-indent:0pt"><span
            style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><a
            name="_844-1599121613465"></a><span
            style="color:#595959;  font-size:12pt; font-weight:bold">基础兼容性</span><span
            style="color:#595959;  font-size:12pt">：安装、启动、升级、卸载、应用崩溃、应用无响应、TargetSdkVersion</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt 21pt; text-indent:0pt"><span
            style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><a
            name="_565-1599121613467"></a><span
            style="color:#595959;  font-size:12pt; font-weight:bold">功能兼容性</span><span
            style="color:#595959;  font-size:12pt">：相机、音频、视频、定位、计步、指纹、图片选取、分享、账号登录、页面旋转基本功能兼容性</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt 21pt; text-indent:0pt"><span
            style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><a
            name="_410-1599121613472"></a><span
            style="color:#595959;  font-size:12pt; font-weight:bold">显示兼容性</span><span
            style="color:#595959;  font-size:12pt">：全面屏、异形屏（搭配不同分辨率、长宽比）、折叠屏兼容性</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt 21pt; text-indent:0pt"><span
            style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><a
            name="_769-1599121613474"></a><span
            style="color:#595959;  font-size:12pt; font-weight:bold">Android特性兼容性</span><span
            style="color:#595959;  font-size:12pt">：新Android版本特性兼容性</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt 21pt; text-indent:0pt"><span
            style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><a
            name="_639-1599121613476"></a><span
            style="color:#595959;  font-size:12pt; font-weight:bold">其他兼容性</span><span
            style="color:#595959;  font-size:12pt">：非SDK接口、64位支持</span></p>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt 21pt"><a name="_780-1599121613477"></a><span
            style="color:#595959;  font-size:12pt">当前阶段本标准会依据这些表象与其典型发生的场景来制定；后续会根据不同的根本原因，补充推荐的修复方式，逐步形成最佳实践。</span>
        </p>
        <p style="font-size:11pt; line-height:175%; margin:10pt 0pt 10pt"><a name="_333-1599121855613"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="margin-top: 20pt;margin-bottom:10pt" class="nav-title" id="3"><a name="_050-1599121613481"></a><span
            style="color:#409eff;  font-size:17pt; font-weight:bold">三. 术语和定义</span></p>
        <p style="margin-top: 10pt;margin-bottom:10pt" class="nav-title" id="3-1"><a name="_562-1599121613481"></a><span
            style="color:#333333;  font-size:14pt; font-weight:bold">3.1 全面屏</span></p>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt 21pt"><a name="_787-1599121613483"></a><span
            style="color:#595959;  font-size:12pt">全面屏是对超高屏占比手机设计的一个比较宽泛的定义。从字面上解释就是手机的正面全部都是屏幕，手机的四个边框位置都是采用无边框设计，追求接近100%的屏占比。</span>
        </p>
        <p style="margin-top: 10pt;margin-bottom:10pt" class="nav-title" id="3-2"><a name="_266-1599121613488"></a><span
            style="color:#333333;  font-size:14pt; font-weight:bold">3.2 异形屏</span></p>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt 21pt"><a name="_157-1599121613489"></a><span
            style="color:#595959;  font-size:12pt">异形屏是指在全面屏上显示刘海，水滴和圆孔，异形区域用来显示摄像头/听筒等。在全面屏的基础上进一步提高屏占比。</span>
        </p>
        <p style="margin-top: 10pt;margin-bottom:10pt" class="nav-title" id="3-3"><a name="_560-1599121613491"></a><span
            style="color:#333333;  font-size:12pt; font-weight:bold">3.3 折叠屏</span></p>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt 21pt"><a name="_900-1599121613493"></a><span
            style="color:#595959;  font-size:12pt">折叠屏是建立在柔性屏幕材料上的一种新型屏幕形态,可以实现360度弯曲。当展开时，可以呈现更大的屏幕显示区域；当折叠时，可以恢复到正常的屏幕尺寸大小，兼具扩展和便携性。</span>
        </p>
        <p style="margin-top: 10pt;margin-bottom:10pt" class="nav-title" id="3-4"><a name="_096-1599121613498"></a><span
            style="color:#333333;  font-size:14pt; font-weight:bold">3.4 应用崩溃</span></p>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt 21pt"><a name="_767-1599121613499"></a><span
            style="color:#595959;  font-size:12pt">在用户正常操作的情况下，应用突然出现应用强行退出、异常停止运行等完全不可用的情况。</span></p>
        <p style="margin-top: 10pt;margin-bottom:10pt" class="nav-title" id="3-5"><a name="_940-1599121613502"></a><span
            style="color:#333333;  font-size:14pt; font-weight:bold">3.5 应用无响应</span></p>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt 21pt"><a name="_616-1599121613503"></a><span
            style="color:#595959;  font-size:12pt">在用户正常操作的情况下，应用突然弹出对话框提示用户是否选择关闭该程序或是继续等待。</span></p>
        <p style="margin-top: 10pt;margin-bottom:10pt" class="nav-title" id="3-6"><a name="_123-1599121613505"></a><span
            style="color:#333333;  font-size:14pt; font-weight:bold">3.6 非SDK接口</span></p>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt 21pt"><a name="_196-1599121613506"></a><span
            style="color:#595959;  font-size:12pt">非SDK 接口指的是系统没有对外开放的接口，即某些SDK中的私有方法；非SDK 接口的处理是API
            抽象出来的实现细节，因此这些接口可能会在不另行通知的情况下随时发生更改。所以应用如果通过Java反射或者JNI 的方式间接使用非SDK接口会导致应用在新的系统版本上出现不兼容的问题。</span><span
            style="color:#595959;  font-size:12pt">&#xa0;</span></p>
        <p style="margin-top: 10pt;margin-bottom:10pt" class="nav-title" id="3-7"><a name="_370-1599121613514"></a><span
            style="color:#333333;  font-size:14pt; font-weight:bold">3.7 TargetSdkVersion</span></p>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt 21pt"><a name="_480-1599121613515"></a><span
            style="color:#595959;  font-size:12pt">应用兼容的目标系统版本，除非更新targetSdkVersion，否则不改变应用的行为。targetSdkVersion保证的是api的一致性。</span>
        </p>
        <p style="margin-top: 10pt;margin-bottom:10pt" class="nav-title" id="3-8"><a name="_089-1599121613518"></a><span
            style="color:#333333;  font-size:14pt; font-weight:bold">3.8 应用64位支持</span></p>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt 21pt"><a name="_086-1599121613520"></a><span
            style="color:#595959;  font-size:12pt">应用64位支持即应用支持64位CPU架构，64位CPU可以为用户带来更快速和更丰富的体验。应用64位版本不但可以提升性能、方便日后进行创新，还可以为后续</span><span
            style="color:#595959;  font-size:12pt">&#xa0;</span><span
            style="color:#595959;  font-size:12pt">支持采用纯 64 位硬件的设备</span><span
            style="color:#595959;  font-size:12pt">&#xa0;</span><span
            style="color:#595959;  font-size:12pt">做好准备。</span></p>
        <p style="font-size:11pt; line-height:175%; margin:10pt 0pt 10pt"><a name="_153-1599121613527"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="margin-top: 20pt;margin-bottom:10pt" class="nav-title" id="4"><a name="_675-1599121863478"></a><span
            style="color:#409eff;  font-size:17pt; font-weight:bold">四. 兼容性标准</span></p>
        <p style="margin-top: 10pt;margin-bottom:10pt" class="nav-title" id="4-1"><a name="_168-1599121613526"></a><span
            style="color:#333333;  font-size:14pt; font-weight:bold">4.1 基础兼容性</span></p>
        <p style="margin-top: 10pt;margin:10pt 0pt 10pt 21pt" class="nav-title" id="4-1-1"><a name="_585-1599121613526"></a><span
            style="color:#595959;  font-size:12pt; font-weight:bold">4.1.1 应用安装</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:22pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">测试应用能否正常安装</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用未在手机上安装</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">根据应用是否有桌面图标区分：</span></p>
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">1 在桌面可以看到应用图标，则安装成功</span></p>
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">2 在设置-更多设置-应用程序查看，在列表中则安装成功</span></p>
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用在手机上能正常安装</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="font-size:11pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt 21pt" class="nav-title" id="4-1-2"><a name="_260-1599121613549"></a><span
            style="color:#595959;  font-size:12pt; font-weight:bold">4.1.2 应用启动</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:22pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">测试应用能否正常启动</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用在手机上安装成功</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用可以正常启动，进入应用主页面</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="font-size:11pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt 21pt" class="nav-title" id="4-1-3"><a name="_870-1599121613569"></a><span
            style="color:#595959;  font-size:12pt; font-weight:bold">4.1.3 应用卸载</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:22pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">测试应用卸载后再安装是否正常</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用在手机上安装成功</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">根据应用是否有桌面图标区分：</span></p>
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">1.在桌面可以看到应用图标，则安装成功</span></p>
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">2.在设置-更多设置-应用程序查看，在列表中则安装成功</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="font-size:11pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt 21pt" class="nav-title" id="4-1-4"><a name="_389-1599121613583"></a><span
            style="color:#595959;  font-size:12pt; font-weight:bold">4.1.4 应用版本升级</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:22pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">测试应用能否升级成功</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用在手机上安装成功</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用可正常升级到最新版本，可在应用内部或应用程序列表查看应用版本</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="font-size:11pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt 21pt" class="nav-title" id="4-1-5"><a name="_333-1599121613602"></a><span
            style="color:#595959;  font-size:12pt; font-weight:bold">4.1.5 应用崩溃</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:22pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">测试应用运行过程中是否出现运行崩溃现象</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用在手机上安装成功</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用未出现停止运行和闪退现象</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="font-size:11pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt 21pt" class="nav-title" id="4-1-6"><a name="_385-1599121613623"></a><span
            style="color:#595959;  font-size:12pt; font-weight:bold">4.1.6 应用无响应</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:22pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">测试应用运行过程中是否出现运行无响应现象</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用在手机上安装成功</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用未出现运行无响应现象。</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="font-size:11pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt 21pt" class="nav-title" id="4-1-7"><a name="_311-1599121613639"></a><span
            style="color:#595959;  font-size:12pt; font-weight:bold">4.1.7 TargetSdkVersion</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:22pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">测试应用指定兼容的目标版本，根据工信部的要求，2019年8月1日起新已上架应用的更新需符合targetSdkVersion的新要求</span>
              </p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用在手机上安装成功</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用targetSdkVersion不小于26</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="font-size:11pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:14pt; line-height:175%; margin:10pt 0pt 10pt;margin-top: 10pt;" class="nav-title" id="4-2"><a name="_478-1599121613654"></a><span
            style="color:#333333;  font-size:14pt; font-weight:bold">4.2 功能兼容性</span></p>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt 21pt" class="nav-title" id="4-2-1"><a name="_990-1599121613654"></a><span
            style="color:#595959;  font-size:12pt; font-weight:bold">4.2.1 相机功能</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:22pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">测试应用是否能够正常使用相机功能</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">1.应用在手机上安装成功</span></p>
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">2.应用使用相机功能</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用调用相机功能正常</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="font-size:11pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt 21pt" class="nav-title" id="4-2-2"><a name="_038-1599121613674"></a><span
            style="color:#595959;  font-size:12pt; font-weight:bold">4.2.2 音频器件功能</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:22pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">测试应用是否能够正常使用音频器件功能</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">1.应用在手机上安装成功</span></p>
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">2.应用使用麦克风语音功能</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用使用音频器件功能正常</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="font-size:11pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt 21pt" class="nav-title" id="4-2-3"><a name="_750-1599121613686"></a><span
            style="color:#595959;  font-size:12pt; font-weight:bold">4.2.3 定位功能</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:22pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">测试应用是否能够正常使用定位功能</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">1.应用在手机上安装成功</span></p>
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">2.应用使用定位功能</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用调用定位功能正常</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="font-size:11pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt 21pt" class="nav-title" id="4-2-4"><a name="_957-1599121613703"></a><span
            style="color:#595959;  font-size:12pt; font-weight:bold">4.2.4 选择图片功能</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:22pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">测试应用能够正常使用选择图片功能</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">1.应用在手机上安装成功</span></p>
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">2.应用需要选择系统图片(截图，相机照片)</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用调用选择图片功能正常</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="font-size:11pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt 21pt" class="nav-title" id="4-2-5"><a name="_830-1599121613717"></a><span
            style="color:#595959;  font-size:12pt; font-weight:bold">4.2.5 旋转屏幕功能</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:22pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">测试应用是否能够正常响应旋转屏幕功能</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">1应用在手机上安装成功</span></p>
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">2应用自身支持旋转屏幕</span></p>
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">3手机支持旋转功能默认开启</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用正常旋转屏幕</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="font-size:11pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt 21pt" class="nav-title" id="4-2-6"><a name="_521-1599121613728"></a><span
            style="color:#595959;  font-size:12pt; font-weight:bold">4.2.6 视频播放功能</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:22pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">测试应用视频播放功能是否正常</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">1.应用在手机上安装成功</span></p>
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">2.应用有视频播放功能</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用能够正常播放应用业务支持视频</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="font-size:11pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt 21pt" class="nav-title" id="4-2-7"><a name="_400-1599121613741"></a><span
            style="color:#595959;  font-size:12pt; font-weight:bold">4.2.7 分享功能</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:22pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">测试应用是否能够正常使用分享功能</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">1.应用在手机上安装成功</span></p>
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">2.应用有分享到第三方应用功能(包含微博，微信和QQ等)</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用可以正常分享内容到第三方应用</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="font-size:11pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt 21pt" class="nav-title" id="4-2-8"><a name="_088-1599121613763"></a><span
            style="color:#595959;  font-size:12pt; font-weight:bold">4.2.8 计步功能</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:22pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">测试应用计步功能</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">1.应用在手机上安装成功</span></p>
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">2.应用有使用计步功能</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用可以正常计步</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="font-size:11pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt 21pt" class="nav-title" id="4-2-9"><a name="_859-1599121613779"></a><span
            style="color:#595959;  font-size:12pt; font-weight:bold">4.2.9 指纹功能</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:22pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">测试应用能否正常使用指纹功能</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">1.应用安装成功</span></p>
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">2.手机有指纹功能</span></p>
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">3.应用有调用指纹相关功能场景</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用可以正常使用指纹功能</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="font-size:11pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt 21pt" class="nav-title" id="4-2-10"><a name="_085-1599121613787"></a><span
            style="color:#595959;  font-size:12pt; font-weight:bold">4.2.10 第三方账号登录</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:22pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">测试第三方账号登录认证是否正常</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">1.应用在手机上安装成功</span></p>
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">2.应用支持第三方账号认证登录功能</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用可以正常使用第三方账号认证登录</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="font-size:11pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:14pt; line-height:175%; margin:10pt 0pt 10pt;margin-top: 10pt;" class="nav-title" id="4-3"><a name="_037-1599121613801"></a><span
            style="color:#333333;  font-size:14pt; font-weight:bold">4.3 显示兼容性</span></p>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt 21pt" class="nav-title" id="4-3-1"><a name="_696-1599121613804"></a><span
            style="color:#595959;  font-size:12pt; font-weight:bold">4.3.1 全面屏</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:22pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">测试应用能够在全面屏正常显示</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用在全面屏手机上安装成功</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用在全面屏手机上全屏正常显示</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="font-size:11pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt 21pt" class="nav-title" id="4-3-2"><a name="_996-1599121613816"></a><span
            style="color:#595959;  font-size:12pt; font-weight:bold">4.3.2 异形屏</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:22pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">测试应用能否在异形屏上正常显示</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用在异形屏手机上安装成功</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用在异形屏手机上正常显示</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="font-size:11pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt 21pt" class="nav-title" id="4-3-3"><a name="_562-1599121613825"></a><span
            style="color:#595959;  font-size:12pt; font-weight:bold">4.3.3 折叠屏</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:22pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">测试应用能否在折叠屏上正常显示</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用在折叠屏手机上安装成功</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用在折叠屏手机上正常显示</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="font-size:11pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:14pt; line-height:175%; margin:10pt 0pt 10pt;margin-top: 10pt;" class="nav-title" id="4-4"><a name="_357-1599121613838"></a><span
            style="color:#333333;  font-size:14pt; font-weight:bold">4.4 Android特性兼容性</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:22pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">测试应用是否及时进行了Android最新版本适配</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用在手机上安装成功</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用在Android新版本正式发布一个月以内完成适配</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">推荐标准</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:14pt; line-height:175%; margin:10pt 0pt 10pt;margin-top: 10pt;" class="nav-title" id="4-5"><a name="_314-1599121613847"></a><span
            style="color:#333333;  font-size:14pt; font-weight:bold">4.5 其他兼容性</span></p>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt 21pt" class="nav-title" id="4-5-1"><a name="_166-1599121613847"></a><span
            style="color:#595959;  font-size:12pt; font-weight:bold">4.5.1 非SDK接口</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:22pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">测试应用是否使用非SDK接口</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用在手机上安装成功</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用不使用非SDK接口</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">推荐标准</span></p>
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">非SDK最新名单：</span></p>
              <p style="font-size:11pt; line-height:175%; margin:10pt 0pt 10pt"><a style="color:#528cd8"
                  href="https://android.googlesource.com/platform/prebuilts/runtime/+/master/appcompat/hiddenapi-flags.csv"><span
                    style="color:#528cd8;  font-size:11pt; text-decoration:underline">https://android.googlesource.com/platform/prebuilts/runtime/+/master/appcompat/hiddenapi-flags.csv</span></a>
              </p>
            </td>
          </tr>
        </table>
        <p style="font-size:12pt; line-height:175%; margin:10pt 0pt 10pt 21pt" class="nav-title" id="4-5-2"><a name="_635-1599121613858"></a><span
            style="color:#595959;  font-size:12pt; font-weight:bold">4.5.2 应用64位支持</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:22pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">测试应用是否支持64位指令集</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用在手机上安装成功</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">应用支持64位指令集</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt;  vertical-align:middle">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 0pt"><span
                  style="color:#595959;  font-size:11pt">推荐标准</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height:175%; margin:10pt 0pt 10pt"><a name="_928-1599121613927"></a><span
            style="font-size:11pt">&#xa0;</span></p>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import Nav from "@/components/documentsBase/Nav";
export default {
  components: { Nav },
  metaInfo: {
    title:'ITGSA | 移动智能终端生态联盟 | 应用兼容性标准',
    meta: [
      {
        name: 'keywords',
        content: 'ITGSA,生态联盟,兼容性,金标认证'
      },
      {
        name: 'description',
        content: '应用兼容性标准目的是提升安卓应用体验，营造良好的安卓应用使用环境，推动安卓应用软件运行在手机上的健康发展，建立安全可靠、值得信赖、健康的应用生态环境体系。'
      },
    ]
  },
  created() {},
  data() {
    return {
      navFixed: [
          {
            name: "一、 背景",
            isActive:null,
            class:'#1',
          },
          {
            name: "二、 适用范围",
            isActive:null,
            class:'#2',
          },
          {
            name: "三、 术语和定义",
            isActive:null,
            class:'#3',
            child:[
              {
                name: "3.1 全面屏",
                isActive:null,
                class:'#3-1',
              },
              {
                name: "3.2 异形屏",
                isActive:null,
                class:'#3-2',
              },
              {
                name: "3.4 应用崩溃",
                isActive:null,
                class:'#3-4',
              },
              {
                name: "3.5 应用无响应",
                isActive:null,
                class:'#3-5',
              },
              {
                name: "3.6 非SDK接口",
                isActive:null,
                class:'#3-6',
              },
              {
                name: "3.7 TargetSdkVersion",
                isActive:null,
                class:'#3-7',
              },
              {
                name: "3.8 应用64位支持",
                isActive:null,
                class:'#3-8',
              },
            ],
          },
          {
            name: "四、 兼容性标准",
            isActive:null,
            class:'#4',
            child:[
              {
                name: "4.1 基础兼容性",
                isActive:null,
                class:'#4-1',
                child:[
                  {
                    name: "4.1.1 应用安装",
                    isActive:null,
                    class:'#4-1-1',
                  },
                  {
                    name: "4.1.2 应用启动",
                    isActive:null,
                    class:'#4-1-2',
                  },
                  {
                    name: "4.1.3 应用卸载",
                    isActive:null,
                    class:'#4-1-3',
                  },
                  {
                    name: "4.1.4 应用版本升级",
                    isActive:null,
                    class:'#4-1-4',
                  },
                  {
                    name: "4.1.5 应用崩溃",
                    isActive:null,
                    class:'#4-1-5',
                  },
                  {
                    name: "4.1.6 应用无响应",
                    isActive:null,
                    class:'#4-1-6',
                  },
                  {
                    name: "4.1.7 TargetSdkVersion",
                    isActive:null,
                    class:'#4-1-7',
                  },
                ],
              },
              {
                name: "4.2 功能兼容性",
                isActive:null,
                class:'#4-2',
                child:[
                  {
                    name: "4.2.1 相机功能",
                    isActive:null,
                    class:'#4-2-1',
                  },
                  {
                    name: "4.2.2 音频器件功能",
                    isActive:null,
                    class:'#4-2-2',
                  },
                  {
                    name: "4.2.3 定位功能",
                    isActive:null,
                    class:'#4-2-3',
                  },
                  {
                    name: "4.2.4 选择图片功能",
                    isActive:null,
                    class:'#4-2-4',
                  },
                  {
                    name: "4.2.5 旋转屏幕功能",
                    isActive:null,
                    class:'#4-2-5',
                  },
                  {
                    name: "4.2.6 视频播放功能",
                    isActive:null,
                    class:'#4-2-6',
                  },
                  {
                    name: "4.2.7 分享功能",
                    isActive:null,
                    class:'#4-2-7',
                  },
                  {
                    name: "4.2.8 计步功能",
                    isActive:null,
                    class:'#4-2-8',
                  },
                  {
                    name: "4.2.9 指纹功能",
                    isActive:null,
                    class:'#4-2-9',
                  },
                  {
                    name: "4.2.10 第三方账号登录",
                    isActive:null,
                    class:'#4-2-10',
                  },
                ],
              },
              {
                name: "4.3 显示兼容性",
                isActive:null,
                class:'#4-3',
                child:[
                  {
                    name: "4.3.1 全面屏",
                    isActive:null,
                    class:'#4-3-1',
                  },
                  {
                    name: "4.3.2 异形屏",
                    isActive:null,
                    class:'#4-3-2',
                  },
                  {
                    name: "4.3.3 折叠屏",
                    isActive:null,
                    class:'#4-3-3',
                  },
                ],
              },
              {
                name: "4.4 Android特性兼容性",
                isActive:null,
                class:'#4-4',
              },
              {
                name: "4.5 其他兼容性",
                isActive:null,
                class:'#4-5',
                child:[
                  {
                    name: "4.5.1 非SDK接口",
                    isActive:null,
                    class:'#4-5-1',
                  },
                  {
                    name: "4.5.2 应用64位支持",
                    isActive:null,
                    class:'#4-5-2',
                  },
                ],
              },
            ],
          },
        ],
    };
  },
  mounted() {
    $('.nav-fixed-box .name').eq(0).css("color","#1989fa");
    this.scrollContainer = this.$refs['view-box'].wrap;  // 将 el-scrollbar 的 wrap 对象找出来，指定给 scroll-container 
    this.scrollContainer.addEventListener('scroll',utils.throttle(this.handleScroll,100,200));
  },
  methods: {
    scrollView(event){
      if($(event.target).attr("view")){
        this.$refs['view-box'].wrap.scrollTop = $($(event.target).attr("view")).position().top ;
      }
    },
    //滚动检查
    handleScroll() {
      //获取滚动时的高度
      let scrollTop = this.scrollContainer.scrollTop;
      if(scrollTop){
        for(let i=($(".nav-title").length-1);i>=0;i--){
          if (scrollTop > $(".nav-title").eq(i).position().top-30) { 
            $('.nav-fixed-box .name').css("color","#000");
            $('.nav-fixed-box .name').eq(i).css("color","#1989fa");
            this.awaitScroll($('.nav-fixed-box .name').eq(i).position().top);
            break;
          }
        }
      }else{
        $('.nav-fixed-box .name').css("color","#000");
        $('.nav-fixed-box .name').eq(0).css("color","#1989fa");
        this.awaitScroll(0);
      }
    },
    //同步目录滚动
    awaitScroll(top) {
      $('.nav-fixed-box').animate({
        scrollTop:top
      },300);
    },
  },
  destroyed() {
    this.scrollContainer.removeEventListener('scroll', this.handleScroll)
  },
};
</script>

<style scoped lang="scss">
/*主体*/
.page-box{
  position: relative;
  display: flex;
  .nav-fixed-box{
    width:300px;
    height: 100%;
    padding: 40px 15px;
    overflow-y: scroll;
    .nav-fixed{
      position: relative;
    }
  }
  .view-box{
    position: relative;
    padding: 60px;
  }
}
@media screen and (max-width: 800px) {
    .nav-fixed-box {
      display: none;
    }
}
</style>
